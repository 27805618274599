/*=========================================================================================
  File Name: Routering.js
  Description: Router paths config
==========================================================================================*/

const paths = {
  // Pages
  page404: {
    name: 'Chyba',
    path: '/pages/404',
    translation_key: '__stranka_neexistuje__'
  },
  page500: {
    name: 'Interní chyba',
    path: '/pages/500'
  },
  login: {
    name: 'Login',
    path: '/login/default',
    translation_key: '__login__'
  },
  about: {
    name: 'O systému',
    path: '/about-system',
    translation_key: '__o_systemu__'
  },
  'forgotten-password': {
    name: 'Zapomenuté heslo',
    path: '/forgotten-password',
    translation_key: '__zapomenute_heslo__'
  },

  // Default page
  default: {
    name: 'Uživatelé',
    path: '/admin/users/'
    // name: 'Hlavní stránka',
    // path: '/'
  },

  // Settings
  users: {
    name: 'Uživatelé',
    path: '/admin/users/',
    translation_key: '__uzivatele__'
  },
  firms: {
    name: 'Dodavatelé',
    path: '/admin/firms/',
    translation_key: '__firmy__'
  },
  formstructure: {
    name: 'Struktura formuláře',
    path: '/admin/formstructure/',
    translation_key: '__struktura_formulare__'
  },
  formfields: {
    name: 'Formulář',
    path: '/admin/formfields/',
    translation_key: '__formular__'
  },
  email_notifications: {
    name: 'Emailové notifikace',
    path: '/admin/email-notifications/',
    translation_key: '__emailove_notifikace__'
  },
  languages: {
    name: 'Jazyky',
    path: '/admin/languages/',
    translation_key: '__jazyky__'
  },
  developer: {
    name: 'Developer',
    path: '/admin/developer/',
    translation_key: '__developer__',
    developer: true
  },

  // Suppliers - Data
  firms_products: {
    name: 'Dodavatelé - data',
    path: '/firms-products/',
    translation_key: '__dodavatelske_produkty__'
  },
  all_products: {
    name: 'Produkty - fulltext vyhledávání',
    path: '/all-products/',
    translation_key: '__vsechny_produkty__'
  },
  firms_products_product_detail: {
    name: 'Dodavatelé - detail produktu',
    path: '/firms-products/product&item=:id'
  },
  notificationspool: {
    name: 'Fronta notifikací',
    path: '/notificationspool/',
    translation_key: '__fronta_notifikaci__'
  },
  listed_items: {
    name: 'Zalistované položky',
    path: '/listed-items/',
    translation_key: '__zalistovane_polozky__'
  },
  listed_suppliers: {
    name: 'Zalistování dodavatelů',
    path: '/listed-suppliers/',
    translation_key: '__zalistovani_dodavatelu__'
  },
  listed_suppliers_v2: {
    name: 'Zalistování dodavatelů V2',
    path: '/listed-suppliers-v2/',
    translation_key: '__dodavatele_s_nove_zalistovanymi_polozky__'
  },
  extremes: {
    name: 'Extrémy',
    path: '/extremes/',
    translation_key: '__extremy__'
  },
  reports: {
    name: 'Reporty',
    path: '/reports/',
    translation_key: '__reporty__'
  },

  // only firm users routes
  // Default page
  main_page: {
    name: 'Úvod',
    path: '/supplier/',
    translation_key: '__uvod__'
  },
  products: {
    name: 'Seznam položek',
    path: '/supplier/products/',
    translation_key: '__polozky__'
  },
  products_detail: {
    name: 'Detail produktu',
    path: '/supplier/products/detail&item=:id'
  },
  forms: {
    name: 'Formuláře',
    path: '/supplier/forms/',
    translation_key: '__formulare__'
  },
  data_import: {
    name: 'Import dat',
    path: '/supplier/data-import/',
    translation_key: '__import_dat__'
  },
  contacts: {
    name: 'Kontaktní stránka',
    path: '/supplier/contacts/',
    translation_key: '__kontakty__'
  }
};

export const sections = {
  list: {
    name: '',
    path: 'list'
  },
  new: {
    name: ' - nový',
    path: 'new'
  },
  detail: {
    name: ' - detail',
    path: 'detail&item=:id'
  }
};

export const getRoute = (path_key, section_key = false) => {
  let sectionName = '';
  let sectionPath = '';

  if (section_key !== false) {
    sectionName = sections[section_key].name;
    sectionPath = sections[section_key].path;
  }

  return {
    ...paths[path_key],
    name: paths[path_key].name + sectionName,
    path: paths[path_key].path + sectionPath,
    to:
      (paths[path_key].to ? paths[path_key].to : paths[path_key].path) +
      sectionPath,
    path_key: path_key
  };
};

/* set last visited subsection path in each paths */
export const setLastRoute = async (path_key, path) => {
  if (!Object.keys(paths).includes(path_key)) {
    /* Vue.$fnc.notificationAlert(
      'Daná route není definovaná! Nelze nastavit poslední navštívenou podsekci sekce!',
      'warning'
    ); */
    return false;
  }

  paths[path_key].to = path;

  return true;
};
