/*=========================================================================================
  File Name: default.js
  Description: actions requests to default.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'firms';

const state = {
  firmsProducts: []
};

const getters = {
  FIRMS_PRODUCTS: state => {
    return state.firmsProducts;
  }
};

const mutations = {
  ADD_FIRM_PRODUCT(state, payload) {
    // if new firm is already in firms array, exit
    if (Vue.$fnc.arrayFind(state.firmsProducts, 'id', payload.id)) {
      return false;
    }

    // else add new firm
    state.firmsProducts.push(payload);
  },

  UPDATE_FIRM_PRODUCT_DATA(state, payload) {
    Vue.$fnc.updateArrayObject(state.firmsProducts, payload);
  },

  UPDATE_FIRM_PRODUCT_WASTE_ACTIVE(state, payload) {
    const temp = Vue.$fnc.arrayFind(state.firmsProducts, 'id', payload.id);
    if (temp) {
      Vue.set(temp.waste_actives, [payload.countries_id], payload.waste_active);
    }
  }
};

const actions = {
  list: async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'list', payload);

    commit('fetch', [moduleName, 'firmsProducts', data.data['list']], {
      root: true
    });
    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data;
  },

  'show-products': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'show-products', {
      firm_id: payload.id
    });

    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data;
  },

  'product-waste-active-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'product-waste-active-save',
      payload
    );

    return data;
  },

  'firm-waste-active-save': async (context, payload) => {
    const data = await Vue.$api.update(
      moduleName,
      'firm-waste-active-save',
      payload
    );

    if (data['manuallyChangedSupplier']) {
      Vue.$fnc.notificationAlertBasic(
        Vue.$tr(data['manuallyChangedMsg']) +
          ' ' +
          data['manuallyChangedSupplier'] +
          ' (' +
          Vue.$tr('celkem') +
          ': ' +
          data['manuallyChanged'] +
          ')',
        undefined,
        8000
      );
    }

    return data;
  },

  'send-notifications': async (context, payload) => {
    const data = await Vue.$api.index(
      moduleName,
      'send-notifications',
      payload
    );

    return (
      Vue.$auth.searchInStatusList(data.status_list, 'ok') ||
      Vue.$auth.searchInStatusList(data.status_list, 'saved')
    );
  },

  'send-notifications-firm': async (context, payload) => {
    const data = await Vue.$api.post(
      moduleName,
      'send-notifications-firm',
      payload
    );

    return data;
  },

  // not api req
  // manually update data
  updateFirmProductData: async ({ commit }, payload) => {
    commit('UPDATE_FIRM_PRODUCT_DATA', payload);
  },

  updateFirmProductWasteActive: async ({ commit }, payload) => {
    commit('UPDATE_FIRM_PRODUCT_WASTE_ACTIVE', payload);
  },

  addFirmProduct: async ({ commit }, payload) => {
    commit('ADD_FIRM_PRODUCT', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
