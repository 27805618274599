/*=========================================================================================
  File Name: index.js
  Description: Vue Router
==========================================================================================*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import { getRoute, setLastRoute } from './Routering.js';

import pagesError from './routes/pages-error';
import pages from './routes/pages.js';
import RouteSchema from './RouteSchema.js';

// Main
// Container
const TheContainer = () => import('@/layouts/main/TheContainer');

// Settings
const users = new RouteSchema('users', true).getTabsRoutes();
const firms = new RouteSchema('firms', true).getTabsRoutes();
const formstructure = new RouteSchema('formstructure', true).getBasicRoutes();
const formfields = new RouteSchema('formfields', true).getBasicRoutes();
const email_notifications = new RouteSchema(
  'email_notifications',
  true
).getBasicRoutes();
const languages = new RouteSchema('languages', true).getTabsRoutes();
const developer = new RouteSchema('developer', true, true).getBasicRoutes();
const Import = () => import('@/views/admin/import/Import');

// Suppliers - data
const firms_products = new RouteSchema('firms_products', true).getTabsRoutes();

// Všechny produkty
const all_products = new RouteSchema('all_products', true).getBasicRoutes();

// Notificationspool
const notificationspool = new RouteSchema(
  'notificationspool',
  true
).getTabsRoutes();

// Zalistované položky
const listed_items = new RouteSchema('listed_items', true).getBasicRoutes();

// Dodavatelé s nově zalistovanými položky
const listed_suppliers_v2 = new RouteSchema(
  'listed_suppliers_v2',
  true
).getBasicRoutes();

// Zalistování dodavatelů
const listed_suppliers = new RouteSchema(
  'listed_suppliers',
  true
).getBasicRoutes();

// Extrémy
const extremes = new RouteSchema('extremes', true).getBasicRoutes();

// Reporty
const reports = new RouteSchema('reports', true).getBasicRoutes();

// firm users routes
const main_page = new RouteSchema('main_page').getBasicRoutes();
const products = new RouteSchema('products').getBasicRoutes();
// component for both admin and firm users
const ProductsDetail = () => import('@/views/supplier/products/ProductsDetail');
// Supplier - forms
const forms = new RouteSchema('forms').getTabsRoutes();
const data_import = new RouteSchema('data_import').getBasicRoutes();
const contacts = new RouteSchema('contacts').getBasicRoutes();

const routes = [
  // Main
  {
    path: '',
    redirect: getRoute('users'),
    component: TheContainer,
    children: [
      ...pagesError,
      ...users,
      ...firms,
      ...formstructure,
      ...formfields,
      ...email_notifications,
      ...languages,
      ...developer,
      ...firms_products,
      ...all_products,
      ...notificationspool,
      ...listed_items,
      ...listed_suppliers,
      ...listed_suppliers_v2,
      ...extremes,
      ...reports,
      {
        name: 'Import',
        path: '/admin/import:type?',
        component: Import,
        meta: {
          translation_key: '__import__',
          admin: true,
          auth: true,
          developer: true
        }
      },
      {
        ...getRoute('firms_products_product_detail'),
        component: ProductsDetail,
        meta: {
          path_key: 'firms_products_product_detail',
          admin: true,
          auth: true
        }
      },
      {
        ...getRoute('products_detail'),
        component: ProductsDetail,
        meta: {
          path_key: 'products_detail',
          auth: true
        }
      },

      ...main_page,
      ...products,
      /* {
        ...getRoute('products'),
        component: () => import('@/views/supplier/Products'),
        meta: {
          admin: false,
          auth: true
        }
      }, */
      ...forms,
      ...data_import,
      ...contacts
    ]
  },
  // Full pages
  {
    path: '/pages',
    redirect: getRoute('page404').path,
    name: 'Pages',
    component: {
      render(c) {
        return c('router-view');
      }
    },
    children: [...pages]
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: getRoute('page404').path
  }
];

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'hash',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { y: to.meta.pageY };
  }
});

router.beforeEach(async (to, from, next) => {
  from.meta.pageY = window.pageYOffset;

  // kontrola prihlaseneho uzivatele
  if (Vue.$auth.getAccessToken() && Vue.$auth.getAccessToken().length > 0) {
    // pouze jednou po prvnim nacteni stranky
    // jisti to promenna isUserLogged (aby se neposilal pozadavek pri kazde zmene routu (sekce))
    // or
    // check if VUEX and localstorage tokens are the same
    // if not then request new user data
    // solves login as 2 different users in 1 browser
    if (
      !Vue.$store.state.isUserLogged ||
      Vue.$auth.getAccessToken() !== Vue.$store.state.access_token
    ) {
      await Vue.$store.dispatch('request-verify');
    }
  }

  // if user is logged
  if (Vue.$store.state.isUserLogged) {
    // ZABRANENI MOZNOSTI PREJIT NA LOGIN POKUD JE UZIVATEL PRIHLASEN
    if (to.fullPath === getRoute('login').path) {
      next('/');
    }

    /* kontrola vstupu do admin části bez opravneni */
    if (to.meta.admin && !Vue.$store.getters['USER_IS_ADMIN']) {
      next(getRoute('main_page'));
    }

    if (to.fullPath === '/' && Vue.$store.getters['USER_IS_ADMIN']) {
      next(getRoute('users'));
    }

    /* kontrola vstupu do developer části bez opravneni */
    if (to.meta.developer && !Vue.$store.getters['USER_IS_DEVELOPER']) {
      next('/');
    }

    /* if (!to.meta.admin && Vue.$store.getters['USER_IS_ADMIN']) {
      next(getRoute('default'));
    } */

    /* nastaveni posledni navstivene podsekce */
    if (to.meta.path_key) {
      await setLastRoute(to.meta.path_key, to.path);
    }

    next();
  } else {
    // při špatném tokenu přesměruj na prihl. str.
    //alert("Špatný token!")
    if (to.meta.auth) {
      next(getRoute('login').path);
    } else {
      next();
    }
  }
});

router.afterEach(to => {
  // browser tab title
  Vue.nextTick(() => {
    Vue.$fnc.changeDocumentTitle(to, to.params.name);
  });
});

export default router;
