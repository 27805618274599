/*=========================================================================================
  File Name: funkce.js
  Description: Here you can register functions globally
  Usage in VUE: 
    template tag: $fnc.<function>
    script tag: this.$fnc.<function>
    plain js file: 
        import Vue from "vue"
        Vue.$fnc.<function>
==========================================================================================*/
import Vue from 'vue';

export default {
  getUrl(module, action, get = '') {
    let getParams = '';

    if (this.isObject(get)) {
      Object.keys(get).forEach(key => {
        getParams += '&' + key + '=' + get[key];
      });
    } else {
      getParams = get;
    }

    // jestli chybi lomitko v PHP URL, pridej
    let addSlash = '';
    if (Vue.$store.getters['API_URL'].slice(-1) !== '/') {
      addSlash = '/';
    }

    return (
      Vue.$store.getters['API_URL'] +
      addSlash +
      '?m=' +
      module +
      '&act=' +
      action +
      getParams
    );
  },

  elapsedTime(startTime) {
    let x = new Date(startTime);
    let now = new Date();
    let timeDiff = now - x;
    timeDiff /= 1000;

    let seconds = Math.round(timeDiff);
    timeDiff = Math.floor(timeDiff / 60);

    let minutes = Math.round(timeDiff % 60);
    timeDiff = Math.floor(timeDiff / 60);

    let hours = Math.round(timeDiff % 24);
    timeDiff = Math.floor(timeDiff / 24);

    let days = Math.round(timeDiff % 365);
    timeDiff = Math.floor(timeDiff / 365);

    let years = timeDiff;

    if (years > 0) {
      return Vue.$i18n.tc('__pred_roky__', years, { 0: years });
    } else if (days > 0) {
      return Vue.$i18n.tc('__pred_dny__', days, { 0: days });
    } else if (hours > 0) {
      return Vue.$i18n.tc('__pred_hodinami__', hours, { 0: hours });
    } else if (minutes > 0) {
      return Vue.$i18n.tc('__pred_minutami__', minutes, { 0: minutes });
    } else if (seconds < 1) {
      return Vue.$tr('prave_ted');
    } else if (seconds > 0) {
      return Vue.$tr('pred_sekundami', [seconds]);
    }

    return '';
  },

  sortAsc(a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }

    return 0;
  },

  sortDesc(a, b) {
    if (a < b) {
      return 1;
    }
    if (a > b) {
      return -1;
    }

    return 0;
  },

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      if (a[property] !== undefined) {
        if (this.isNumber(a[property])) {
          let result =
            this.roundToDecimals(a[property]) <
            this.roundToDecimals(b[property])
              ? -1
              : this.roundToDecimals(a[property]) >
                this.roundToDecimals(b[property])
              ? 1
              : 0;
          return result * sortOrder;
        }

        let result =
          a[property].toLowerCase() < b[property].toLowerCase()
            ? -1
            : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
        return result * sortOrder;
      }
    };
  },

  getProgressPercentage(num, total) {
    const temp = num / total;
    if (this.isNumber(temp)) {
      return temp * 100;
    }

    return 0;
  },

  checkObjectKeyValue(obj, key, value) {
    if (!this.objectContainsKey(obj, key)) {
      return false;
    }

    return obj[key] === value;
  },

  // check if object contains key
  objectContainsKey(obj, key) {
    if (!this.isObject(obj)) {
      return false;
    }

    return Object.prototype.hasOwnProperty.call(obj, key);
  },

  toggleEnumValues(item_enum, valid_value = 'Y', invalid_value = 'N') {
    if (item_enum === invalid_value) {
      return valid_value;
    }

    return invalid_value;
  },

  // add/remove value from array
  toggleArrayValues(array, value) {
    let temp = array;

    // remove value from array
    if (array.find(item => item == value)) {
      temp = temp.filter(item => item != value);
    } else {
      // add value to array
      temp.push(value);
    }

    return temp;
  },

  // returns array of defined key
  arrayOfKey(arr, key = 'id') {
    let output = [];

    if (!this.isArrayEmpty(arr)) {
      arr.forEach(item => {
        if (item[key] !== undefined) {
          output.push(item[key]);
        }
      });
    }

    return output;
  },

  // return array with highlighted selected item
  arrayWithSelection(array, selected_id) {
    let temp = array.map(b => Object.assign({}, b));

    let selected = this.arrayFind(temp, 'id', selected_id);
    if (selected) {
      Vue.set(selected, '_classes', 'bg-primary text-white');
    }

    return temp;
  },

  // remove duplicate array objects based on ID
  arrayUnique(array) {
    return array.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
  },

  // returns object from array with max value of specified key (default ID)
  findArrayObjectWithMaxValue(array, key = 'id') {
    if (this.isArray(array)) {
      return array.reduce(function(prev, current) {
        return prev[key] > current[key] ? prev : current;
      });
    }

    return {};
  },

  // array find based on array's key and searched value
  //  on success, returns object {}
  arrayFind(array, key = '', value = '') {
    if (!this.isArray(array)) {
      return false;
    }

    return array.find(item => this.compareValues(item[key], value));
  },

  arrayFindMatch(array, key = '', value = '') {
    if (!this.isArray(array)) {
      return false;
    }

    return array.find(item => this.matchLowerCase(item[key], value));
  },

  // array filter based on array's key and searched value
  //  optional: param 'equal' sets if array object key must be equal to searched value or not
  //    default is true
  //  on success, returns array []
  arrayFilter(array, key = '', value = '', equal = true) {
    if (!this.isArray(array)) {
      return false;
    }

    return array.filter(item => this.compareValues(item[key], value) === equal);
  },

  // round number to decimal
  roundToDecimals(num, dec_len = Vue.$store.getters['DEFAULT_DECIMALS']) {
    let tempNum = this.numberCommaToDecimal(num);

    if (this.isNumber(tempNum) && tempNum !== '') {
      return +(Math.round(tempNum + 'e+' + dec_len) + 'e-' + dec_len); // Math.round( tempNum * (10*dec_len) / (10*dec_len) )
    }

    return 0;
  },

  // check if number has decimmals
  isNumberDecimal(num) {
    return String(num).indexOf('.') != -1 || String(num).indexOf(',') != -1;
  },

  // check if payload is a number
  numberCommaToDecimal(data = false) {
    if (this.isNumber(data)) {
      return data
        .toString()
        .replace(',', '.') // replace comma
        .replace(/ /g, '');
    }

    return '';
  },

  // check if payload is a number
  isNumber(data = false) {
    if (data !== false && data !== null && data !== undefined) {
      let dataMod = data
        .toString()
        .replace(',', '.') // replace comma
        .replace(/ /g, ''); // replace spaces

      if (!isNaN(dataMod)) {
        return true;
      } else {
        return false;
      }
    }

    return false; // /^\d+$/.test(data)
  },

  // check if payload is a string
  isString(data = false) {
    if (typeof data === 'string') {
      return true;
    }

    return false;
  },

  // check if payload is an array
  isArray(data = false) {
    if (data instanceof Array) {
      return true;
    }

    return false;
  },

  // check if payload is an array and is empty
  isArrayEmpty(data = false) {
    if (data instanceof Array && data.length > 0) {
      return false;
    }

    return true;
  },

  // check if payload is an object
  isObject(data = null) {
    if (
      typeof data === 'object' &&
      !Array.isArray(data) &&
      data !== null &&
      data !== undefined
    ) {
      return true;
    }

    return false;
  },

  // check if payload is an object and is empty
  isObjectEmpty(data = null) {
    if (
      typeof data === 'object' &&
      data !== null &&
      data !== undefined &&
      Object.keys(data).length > 0
    ) {
      return false;
    }

    return true;
  },

  // comma replace
  numberWithCommas(x) {
    if (String(x).includes(',')) {
      return Number(x.toString().replace(/,/g, '.'));
    }

    return Number(x);
  },

  // split string
  splitText(text = false, split_sign = ',') {
    if (!text) {
      return [];
    }

    return text.split(split_sign);
  },

  // string no diacritics
  stringNoDiacritics(text) {
    if (text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return '';
  },

  // match lower cased values (strict = exact value)
  matchLowerCase(text = '', match_text = '', strict = false) {
    if (strict) {
      return String(text)
        .toLowerCase()
        .includes(String(match_text).toLowerCase());
    }

    return this.stringNoDiacritics(String(text).toLowerCase()).includes(
      this.stringNoDiacritics(String(match_text).toLowerCase())
    );
  },

  // compare values
  compareValues(value1, value2) {
    return String(value1) === String(value2);
  },

  allowOnlyNumbers(evt) {
    let theEvent = evt || window.event;
    // let nums = [0,1,2,3,4,5,6,7,8,9,'.','']

    if (evt.key == '+' || evt.key == '-' || evt.key == 'e') {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  },

  roundToTwo(num) {
    return +(Math.round(num + 'e+2') + 'e-2');
  },

  changeDocumentTitle(route = { name: '', path: '' }, actionName = '') {
    let prefix = Vue.$i18n.t(route.meta.translation_key); // route.name; //

    let interfix = '';

    if (prefix && (actionName || route.params.name)) {
      interfix = ' - ';
    }

    if (actionName) {
      interfix += actionName;
    } else if (route.params.name) {
      interfix += route.params.name;
    }

    let suffix = '';
    if (route.meta.auth || Vue.$store.getters['APP_NAME_VUE']) {
      suffix = ' - ' + Vue.$store.getters['APP_NAME_VUE'];
    }

    // console.log(route);
    document.title = prefix + interfix + suffix;
  },

  numberFormatter(val, dec_len = 2) {
    let temp = new Intl.NumberFormat('cs-CZ', {
      minimumFractionDigits: dec_len,
      maximumFractionDigits: dec_len
    });

    return temp.format(val ? val : 0);
  },

  currencyFormatter(val, dec_len = 2) {
    let temp = new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: dec_len,
      maximumFractionDigits: dec_len
    });

    return temp.format(val ? val : 0);
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  },

  browserDetection(browser = '') {
    // Chrome, Edg, Safari, Firefox
    return navigator.userAgent.indexOf(browser) != -1;
    // console.log(navigator.userAgent.indexOf("Chrome"))
  },

  confirmAction(message = Vue.$tr('opravdu_smazat')) {
    let del = confirm(message);
    return del;
  },

  async save(action, item, dbItems) {
    let dataToSave = this.createDataToSend(item, dbItems);
    const data = await Vue.$store.dispatch(action, dataToSave);

    // if data has ID => success save
    if (this.isObject(data) && data.id > 0) {
      return data;
    }

    // else return false
    return false;
  },

  /* notificationSuccess(messages = ['Úspěch.']) {
    messages.forEach((msg, index) => {
      Vue.prototype.$notify.success({
        title: 'Info',
        message: msg,
        offset: index * 70,
        duration: 1500
        // type: 'success'
      });
    });
  },

  notificationError(
    messages = [
      'Došlo k chybě při zpracování požadavku, prosím kontaktujte správce.'
    ]
  ) {
    messages.forEach((msg, index) => {
      Vue.prototype.$notify.error({
        title: 'Info',
        message: msg,
        offset: index * 70,
        duration: 1500
      });
    });
  }, */

  notificationAlert(
    msg = 'nastala_neocekavana_chyba',
    type = 'info',
    duration = 1500
  ) {
    if (Vue.$fnc.isObject(msg)) {
      return Vue.$toast.open({
        message: Vue.$tr(msg.key, msg.interpolations),
        type: type,
        duration: duration
      });
    }

    Vue.$toast.open({
      message: Vue.$tr(msg),
      type: type,
      duration: duration
    });
  },

  notificationAlertBasic(msg = '', type = 'info', duration = 1500) {
    Vue.$toast.open({
      message: msg,
      type: type,
      duration: duration
    });
  },

  updateArrayObject(sourceArray, objectWithNewValues) {
    // if is not object, exit
    if (!this.isObject(objectWithNewValues) || !objectWithNewValues.id) {
      return false;
    }

    // find array's object to be updated
    let objectToBeUpdated = sourceArray.find(
      item => parseInt(item.id) === parseInt(objectWithNewValues.id)
    );
    if (objectToBeUpdated) {
      Object.keys(objectWithNewValues).forEach(key => {
        objectToBeUpdated[key] = objectWithNewValues[key];
      });
    }
  },

  /* updateArrayObject(array, obj) {
    if (obj && obj.id > 0) {
      if (array.find(item => item.id == obj.id)) {
        array = array.map(item => (obj.id == item.id ? obj : item));
      } else {
        array.push(obj);
      }
    }

    return array;
  }, */

  returnObjectWithoutSpecifiedKey(obj, keyToRemove) {
    if (!this.isObject(obj)) return;

    return Object.keys(obj)
      .filter(key => key !== keyToRemove)
      .reduce((newObj, key) => {
        newObj[key] = obj[key];
        return newObj;
      }, {});
  },

  updateObjectWithKeys(obj, key, data) {
    if (Object.keys(obj).includes(key)) {
      obj[key] = data;
    } else {
      Vue.set(obj, [key], data);
    }

    return obj;
  },

  createSelectOptions(
    data,
    valueKey = 'id',
    labelKey = 'name',
    radioKey = false
  ) {
    let temp = [];
    let temp_key = {};

    if (radioKey !== false) {
      temp_key = { key: radioKey };
    }

    if (Array.isArray(data) && data.length > 0) {
      data.forEach(item => {
        let obj = {
          key: item['key'],
          value: String(item[valueKey]),
          label: item[labelKey],
          label_no_diacritics: this.stringNoDiacritics(item[labelKey]),
          text: item[labelKey],
          ...item
        };

        temp.push({ ...temp_key, ...obj });
      });
    }

    return temp;
  },

  updateObjectValues(obj, new_obj) {
    if (this.isObject(new_obj)) {
      Object.keys(new_obj).forEach(key => {
        // pouze kdyz obj ma klic a nova hodnota neni undefined
        if (Object.keys(obj).includes(key) && new_obj[key] !== undefined) {
          obj[key] = new_obj[key];
          //Vue.set(obj, [key], new_obj[key])
        }
      });
    }
  },

  createArrayValues(obj) {
    let temp = [];
    if (this.isObject(obj)) {
      Object.keys(obj).forEach(key => {
        temp.push(obj[key]);
      });
    }

    return temp;
  },

  createDataToSend(data, columns) {
    let dataToSave = {};

    if (Array.isArray(columns)) {
      columns.forEach(key => {
        if (data[key] !== undefined) {
          Vue.set(dataToSave, [key], data[key]);
        }
      });
    } else {
      dataToSave = data;
    }

    return dataToSave;
  },

  createStringWithMark(obj, keys, mark = ',') {
    let temp = [];

    keys.forEach(key => {
      if (obj[key]) temp.push(obj[key]);
    });

    return temp.join(mark + ' ');
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  firstCharToUpperCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  zeroPrefix(num, digit) {
    if (digit) {
      return String(num).padStart(2, '0');
    }

    return num;
  },

  dateDiffInDays(start_date, end_date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      start_date.getFullYear(),
      start_date.getMonth(),
      start_date.getDate()
    );
    const utc2 = Date.UTC(
      end_date.getFullYear(),
      end_date.getMonth(),
      end_date.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },

  dateDiffInDaysRound(start_date, end_date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    return ((end_date - start_date) / _MS_PER_DAY).toFixed(1);
  },

  dateModifyDays(date = new Date(), days = 0, sign = '+') {
    // let temp = new Date(date);

    if (sign === '-') {
      date.setDate(date.getDate() - Number(days));
    } else {
      date.setDate(date.getDate() + Number(days));
    }

    return this.dateFormat(date);
  },

  // check if value is date
  isAnyDateFormat(value) {
    const dateFormatRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;
    const dateTimeFormatRegex = /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}$/;

    return dateFormatRegex.test(value) || dateTimeFormatRegex.test(value);
  },

  isDateObject(date) {
    return Object.prototype.toString.call(date) === '[object Date]';
  },

  dateToCz(
    date_def,
    {
      display_time = false,
      display_sec = false,
      no_year_logic = false,
      separator = ' '
    } = {}
  ) {
    if (!date_def) {
      return '';
    }
    if (!this.isDateObject(date_def) && !this.isAnyDateFormat(date_def)) {
      return '';
    }

    let date = null;
    if (this.isDateObject(date_def)) {
      date = date_def;
    } else {
      date = new Date(date_def.replace(/\s/, 'T'));
    }

    if (this.isDateObject(date)) {
      let dateOutput = '';
      let timeOutput = '';

      let day = this.zeroPrefix(date.getDate(), 2);
      let month = this.zeroPrefix(date.getMonth() + 1, 2);
      let year = date.getFullYear();

      if (no_year_logic) {
        let curYear = new Date().getFullYear();

        if (curYear !== year) {
          dateOutput = day + '.' + month + '.' + year;
        } else {
          dateOutput = day + '.' + month + '.';
        }
      } else {
        dateOutput = day + '.' + month + '.' + year;
      }

      if (display_time !== false) {
        timeOutput =
          separator +
          ' ' +
          this.zeroPrefix(date.getHours(), 2) +
          ':' +
          this.zeroPrefix(date.getMinutes(), 2);

        if (display_sec !== false) {
          timeOutput += ':' + this.zeroPrefix(date.getSeconds(), 2);
        }
      }

      return String(dateOutput + timeOutput);
    } else {
      return '';
    }
  },

  dateFormat(date = new Date(), format = 'en') {
    let temp = '';
    let day = this.zeroPrefix(date.getDate(), 2);
    let month = this.zeroPrefix(date.getMonth() + 1, 2);
    let year = date.getFullYear();

    if (format === 'en') {
      temp = year + '-' + month + '-' + day;
    } else if (format === 'cs') {
      temp = day + '.' + month + '.' + year;
    }

    return String(temp);
  },

  dateTimeFormat(date = new Date(), format = 'en', display_time = true) {
    let temp = '';
    let year = date.getFullYear();
    let day = this.zeroPrefix(date.getDate(), 2);
    let month = this.zeroPrefix(date.getMonth() + 1, 2);

    if (format === 'en') {
      temp = year + '-' + month + '-' + day;
    } else if (format === 'cs') {
      temp = day + '.' + month + '.' + year;
    }

    let time = '';
    if (display_time) {
      let hours = this.zeroPrefix(date.getHours(), 2);
      let mins = this.zeroPrefix(date.getMinutes(), 2);
      let secs = this.zeroPrefix(date.getSeconds(), 2);
      time = hours + ':' + mins + ':' + secs;
    }

    return String(temp + ' ' + time);
  },

  checkFileExtension(file_path) {
    if (!file_path) return;
    //console.log(file_path)
    //if(path.includes('.jpg') || path.includes('.png'))
    const docExt = ['pdf', 'doc', 'docx', 'odt', 'xls', 'xlsx', 'txt'];
    const imgExt = ['jpg', 'png'];

    let fPath = this.splitText(file_path, '.').pop();

    for (let doc of docExt) {
      if (fPath === doc) return 'document';
    }

    for (let img of imgExt) {
      if (fPath === img) return 'image';
    }
  },

  roundToDecimal(num, dec_len) {
    return Number(num).toFixed(dec_len);
  },

  isEmail(email) {
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  },

  getImage(image_name) {
    let images = require.context('@/assets/images/', false);
    try {
      return images(`./${image_name}`);
    } catch (error) {
      // return images("./no-image2.jpg");
    }
  }

  // Array compression (not used)
  /* compressArray(array) {
    var ArrayCompress = require('@ericwong3/array-compress');
    var compressor = new ArrayCompress();

    var compressed = compressor.compress(array);

    return compressed;
  },

  decompressArray(compressedArray) {
    var ArrayCompress = require('@ericwong3/array-compress');
    var compressor = new ArrayCompress();

    return compressor.decompress(compressedArray);
  } */
};
