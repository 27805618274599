var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',{class:[_vm.addClassesCard, { 'h-100': _vm.fullHeight, 'border-0': _vm.noBorder }]},[(_vm.showHeader)?_c('CCardHeader',{staticClass:"font-lg bg-header py-2",class:_vm.addClassesHeader},[_vm._t("header")],2):_vm._e(),_c('CCardBody',{staticClass:"p-0",class:{ 'p-3': !_vm.noBorder },style:(_vm.addBodyStyles)},[_c('form',{attrs:{"action.prevent":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[(_vm.item.id > 0)?_c('input',{attrs:{"name":"id","type":"hidden"},domProps:{"value":_vm.item.id}}):_vm._e(),_c('CRow',[_vm._l((_vm.fields.filter(Boolean)),function(field,i){return _c('CCol',{key:i + '-' + field.key,class:[field._classes, { 'd-none': field.hidden }],attrs:{"lg":field.col ? field.col : 12}},[(field.divider)?_c('hr'):_vm._e(),_vm._t(field.key,function(){return [(field.component === 'hidden')?[_c('input',{attrs:{"name":field.key,"type":"hidden"},domProps:{"value":_vm.item[field.key]}})]:_vm._e(),(field.component === 'input')?[_c('base-input',{attrs:{"name":field.key,"value":_vm.item[field.key],"horizontal":field.horizontal,"invalid-feedback":_vm.formValidation.getValidationMessage(field.validation),"is-valid":_vm.formValidation.isValueValid(field.key),"label":field.label,"type":field.type,"disabled":field.disabled,"readonly":field.readonly,"icon":field.icon,"only-numbers":field.onlyNumbers,"on-enter-blur":"","autocomplete":field.type === 'password' ? 'new-password' : 'off',"add-input-classes":{
                      'border-warning': _vm.isValueChanged(field.key)
                    },"add-wrapper-classes":{ 'no-wrap d-flex': field.prepend },"add-classes":field._classes},on:{"update:value":function($event){return _vm.$set(_vm.item, field.key, $event)},"focus":function($event){return _vm.setDefaultFormValue(field.key)}}})]:(field.component === 'select')?[_c('base-select',{attrs:{"name":field.key,"options":field.componentData,"value":_vm.item[field.key],"filterable":field.componentFilterable,"label":field.label,"placeholder":field.componentPlaceholder,"disabled":field.disabled,"no-default-value":field.componentNoDefaultValue,"horizontal":field.horizontal ? field.horizontal : false,"invalidFeedback":_vm.formValidation.getValidationMessage(field.validation),"is-valid":_vm.formValidation.isValueValid(field.key),"add-input-classes":{
                      'border-warning': _vm.isValueChanged(field.key)
                    }},on:{"update:value":function($event){return _vm.$set(_vm.item, field.key, $event)},"click":function($event){return _vm.setDefaultFormValue(field.key)}}})]:(field.component === 'switch')?[(!field.componentHorizontal)?_c('label',{staticClass:"my-auto"},[_vm._v(_vm._s(field.label))]):_vm._e(),_c('div',{staticClass:"d-flex my-2"},[_c('base-switch',{attrs:{"name":field.key,"item":_vm.item,"checked-value":field.checkedValue,"unchecked-value":field.uncheckedValue,"is-changed":_vm.isValueChanged(field.key)},on:{"focus":function($event){return _vm.setDefaultFormValue(field.key)}}}),(field.componentHorizontal)?_c('label',{staticClass:"my-auto ml-2"},[_vm._v(_vm._s(field.label))]):_vm._e()],1)]:(field.component === 'checkbox')?[_c('base-checkbox',{attrs:{"name":field.key,"value":_vm.item[field.key],"checked-value":field.checkedValue,"unchecked-value":field.uncheckedValue,"horizontal":field.componentHorizontal,"label":field.label,"add-label-classes":{
                      'text-warning': _vm.isValueChanged(field.key)
                    }},on:{"update:value":function($event){return _vm.$set(_vm.item, field.key, $event)},"click":function($event){return _vm.setDefaultFormValue(field.key)}}})]:(field.component === 'textarea')?[_c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();}}},[_c('CTextarea',{attrs:{"name":field.key,"label":field.label,"invalidFeedback":_vm.formValidation.getValidationMessage(field.validation),"is-valid":_vm.formValidation.isValueValid(field.key),"add-input-classes":{
                        'border-warning': _vm.isValueChanged(field.key)
                      },"rows":field.componentRows},model:{value:(_vm.item[field.key]),callback:function ($$v) {_vm.$set(_vm.item, field.key, $$v)},expression:"item[field.key]"}})],1)]:(field.component === 'datepicker')?[_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(field.label))]),_c('base-datepicker',{staticClass:"mb-2",attrs:{"placeholder":field.componentPlaceholder,"value":_vm.item[field.key],"name":field.key,"add-classes":{ warning: _vm.isValueChanged(field.key) }},on:{"update:value":function($event){return _vm.$set(_vm.item, field.key, $event)}}})]:(field.component === 'option')?[_c('base-radio',{staticClass:"mb-2",attrs:{"options":field.componentData,"label":field.label,"item":_vm.item,"name":field.key,"add-classes":field._classes}})]:_c('div',[_vm._v(_vm._s(_vm.item[field.key]))])]},{"item":_vm.item,"oldValue":_vm.defaultFormValues[field.key],"isValueChanged":_vm.isValueChanged(field.key)})],2)}),(_vm.$slots['under-inputs'])?_c('CCol',{staticClass:"mb-2",attrs:{"col":"12"}},[_vm._t("under-inputs")],2):_vm._e(),(!_vm.noSaveButton)?_c('CCol',{staticClass:"mx-auto",attrs:{"col":"auto"}},[_vm._t("button",function(){return [_c('base-button',{staticClass:"mx-auto",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$tr(_vm.buttonValue)))])]})],2):_vm._e()],2)],1),_vm._t("under-form")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }